<template>
    <section class="invoice-add-wrapper">
        <div id="surveyCreator" />
    </section>
</template>

<script>
import { SurveyCreator } from "survey-creator-knockout";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
// Survey Builder/Editor In modular applications:
import { editorLocalization } from "survey-creator-core";
import "survey-creator-core/i18n/french";
editorLocalization.currentLocale = "fr";

// Survey Viewer:
import { Model, surveyLocalization } from "survey-core";
import "survey-core/i18n/french";
surveyLocalization.defaultLocale = "fr";

import router from "@/router";
import axios from "axios";

const apiUrl = axios.defaults.baseURL;
let FormID = "1";

const surveyJson = {
    elements: [
        {
            type: "text",
            name: "firstname",
            title: {
                default: "Enter your first name",
                de: "Geben Sie Ihren Vornamen ein",
                fr: "Entrez votre prénom",
            },
        },
    ],
};

const survey = new Model(surveyJson);

const creatorOptions = {
    showLogicTab: true,
    isAutoSave: true,
};

export default {
    name: "survey-creator",
    async mounted() {
        const creator = new SurveyCreator(creatorOptions);

        // Get Form Design
        myApi({
            url: "form_design/" + router.currentRoute.params.id,
            method: "GET",
        })
            .then((response) => {

                console.log(response.status);

                if (response.status == 200) {
                    console.log("show existing design")
                    creator.JSON = response.data.pages;
                    creator.saveSurveyFunc = (saveNo, callback) => {
                        // If you use localStorage:
                        // window.localStorage.setItem("survey-json", creator.text);
                        callback(saveNo, true);

                        // If you use a web service:
                        saveSurveyJson(
                            apiUrl + "form_design/" + router.currentRoute.params.id + "/autosave",
                            creator.JSON,
                            saveNo,
                            callback
                        );
                    };

                    creator.survey.logo = response.data.logo;
                    creator.survey.title = router.currentRoute.params.name;
                    creator.survey.description = router.currentRoute.params.description;
                    // Full mode
                    creator.toolbox.forceCompact = false;

                    creator.render("surveyCreator");
                } else {
                    // error get form design (create new)
                    console.log("create new design")
                    creator.saveSurveyFunc = (saveNo, callback) => {
                        // If you use localStorage:
                        // window.localStorage.setItem("survey-json", creator.text);
                        callback(saveNo, true);

                        // If you use a web service:
                        saveSurveyJson(apiUrl + "form_design/" + router.currentRoute.params.id + "/autosave", creator.JSON, saveNo, callback);
                    };

                    /* creator.survey.logo = response.data.logo;
                    creator.survey.title = router.currentRoute.params.name;
                    creator.survey.description = router.currentRoute.params.description; */
                    // Full mode
                    creator.toolbox.forceCompact = false;

                    creator.render("surveyCreator");
                }
            })
            .catch((error) => {
                // exception
                console.log("Erreur exceptionelle");
                console.log(error)
            });
    },
};

// Save Form Design
async function saveSurveyJson(url, json, saveNo, callback) {
    fetch(url, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(json),
    })
        .then((response) => {
            if (response.ok) {
                callback(saveNo, true);
            } else {
                callback(saveNo, false);
            }
        })
        .catch((error) => {
            callback(saveNo, false);
        });
}
</script>

<style scoped>
#surveyCreator {
    height: 100%;
    width: 100%;
}
</style>
